<template>
  <div class="row">
    <div class="col">
      <router-link :to="{ name: 'Home' }">Menu</router-link>
      <div class="card shadow mt-4">
        <div class="card-body">
          <div class="form-group mb-2 mt-4">
            <label for="password">Nueva Contraseña</label>
            <input
              v-model="password"
              :class="{ 'is-invalid': password && !isPasswordValid() }"
              type="password"
              id="password"
              class="form-control text-center"
              autofocus="autofocus"
            />
          </div>
          <div class="form-group mb-3">
            <label for="passwordConfirmation">Confirmar Contraseña</label>
            <input
              v-model="password_confirmation"
              :class="{
                'is-invalid': password && password !== password_confirmation,
              }"
              type="password"
              id="passwordConfirmation"
              class="form-control text-center"
            />
            <div
              v-if="password && password !== password_confirmation"
              class="invalid-feedback"
            >
              Las contraseñas no coinciden.
            </div>
          </div>
          <hr class="border-gray-400 my-1" />
          <p class="text-center text-gray-600 small mb-0">
            Requisitos mínimos de la contraseña
          </p>
          <div class="password-rules d-flex flex-center">
            <ul class="mw-75 text-start mb-0">
              <li
                :class="{
                  'text-danger': !isMinLength(),
                  'text-success': isMinLength(),
                }"
              >
                Mínimo 8 caracteres
              </li>
              <li
                :class="{
                  'text-danger': !hasUpperCase(),
                  'text-success': hasUpperCase(),
                }"
              >
                Al menos una letra mayúscula
              </li>
              <li
                :class="{
                  'text-danger': !hasLowerCase(),
                  'text-success': hasLowerCase(),
                }"
              >
                Al menos una letra minúscula
              </li>
              <li
                :class="{
                  'text-danger': !hasNumber(),
                  'text-success': hasNumber(),
                }"
              >
                Al menos un número
              </li>
              <li
                :class="{
                  'text-danger': !hasSpecialChar(),
                  'text-success': hasSpecialChar(),
                }"
              >
                Al menos un carácter especial
                <!-- <span class="d-block">¿¡!@#$%^&*(),.?";:{}|<>-_</span> -->
              </li>
            </ul>
          </div>
          <hr class="border-gray-400 mt-2 mb-5" />

          <button
            @click="setPassword"
            :disabled="
              working ||
              !isPasswordValid() ||
              password !== password_confirmation
            "
            class="btn btn-primary w-100"
          >
            Establecer Contraseña
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import API from "@/api";

const working = ref(false);
const password = ref("");
const password_confirmation = ref("");

const router = useRouter();

const setPassword = async () => {
  try {
    working.value = true;

    await API.post(`v1/users/password-set`, {
      password: password.value,
      password_confirmation: password_confirmation.value,
    });
  } catch (error) {
    console.log(error);
  } finally {
    working.value = false;
    router.push({ name: "Home" });
  }
};

const isMinLength = () => password.value.length >= 8;
const hasUpperCase = () => /[A-Z]/.test(password.value);
const hasLowerCase = () => /[a-z]/.test(password.value);
const hasNumber = () => /\d/.test(password.value);
const hasSpecialChar = () => /[¿¡!@#$%^&*(),.?":{}|<>_\-]/.test(password.value);

const isPasswordValid = () => {
  return (
    isMinLength() &&
    hasUpperCase() &&
    hasLowerCase() &&
    hasNumber() &&
    hasSpecialChar()
  );
};
</script>

<style>
.password-rules ul {
  /* list-style-type: none; */
  padding: 0;
}

.password-rules li {
  font-size: small;
  line-height: 1em;
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}
</style>
